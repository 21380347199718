<template>
  <div>
    <public-header mode="transparent" />
    <div class="page-header page-header-small">
      <div
        class="page-header-image"
        data-parallax="true"
      />
      <div class="content-center mt-4">
        <div class="container">
          <h1 class="title h1">
            Contact us
          </h1>
        </div>
      </div>
    </div>
    <div class="wrapper contactUsWrapper my-5">
      <div class="main">
        <div class="contact-content">
          <div class="container">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="info info-horizontal mb-5 py-0">
                  <div class="icon icon-primary mt-2">
                    <i class="now-ui-icons tech_mobile" />
                  </div>
                  <div class="description">
                    <h4 class="info-title mt-0">
                      Our offices are at:
                    </h4>
                    <p>
                      Let's Connect <br>
                      <b>Tel:</b> 800-313-5813 <br>
                      <b>Email:</b> info@letsconnect.solutions <br>
                      Mon - Fri, 8:00-22:00
                    </p>
                  </div>
                </div>

                <div class="section-subtitle text-center mb-4">Let's talk</div>
                <div class="section-title text-center mb-5">
                  Send us a message
                </div>
                <div class="description">
                  We are eager to speak with you. Please fill out the information below. We will do our best to get in touch with you shortly.
                  <br><br>
                </div>
                <div class="featuresBig bg-white py-0">
                  <div class="featureItem text-left">
                    <form
                      id="contact-form"
                      role="form"
                      method="post"
                    >
                      <label>Your name</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons users_circle-08" /></span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Name..."
                          aria-label="Your Name..."
                          autocomplete="name"
                        >
                      </div>
                      <label>Email address</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons ui-1_email-85" /></span>
                        </div>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email Here..."
                          aria-label="Email Here..."
                          autocomplete="email"
                        >
                      </div>
                      <label>Phone</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons tech_mobile" /></span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Number Here..."
                          autocomplete="number"
                        >
                      </div>
                      <div class="form-group">
                        <label>Your message</label>
                        <textarea
                          id="message"
                          name="message"
                          class="form-control"
                          rows="6"
                        />
                      </div>
                      <div class="submit text-center">
                        <input
                          type="submit"
                          class="btn btn-primary btn-raised btn-round"
                          value="Contact Us"
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- <footer class="footer footer-default">
        <div class=" container ">
          <nav>
            <ul>
              <li>
                <a href="#">
                  Let's Connect
                </a>
              </li>
              <li>
                <a href="http://presentation.creative-tim.com">
                  About Us
                </a>
              </li>
              <li>
                <a href="http://blog.creative-tim.com">
                  Blog
                </a>
              </li>
            </ul>
          </nav>
          <div
            id="copyright"
            class="copyright"
          >
            &copy;
          </div>
        </div>
      </footer> -->
    </div>
    <public-footer />
  </div>
</template>

<script>
  export default {
    name: 'ContactUsPage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';

.page-header-image {
  // background-image: radial-gradient(circle 1693px at 100% 100%, var(--clr-secondary) 0%, var(--clr-secondary) 33%, var(--clr-accent-dark) 66%);
  background-color: var(--clr-accent);
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 600px!important;
}
.getStartedButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .getStarted {
    margin-top: 15px;
    background-color: rgb(233, 178, 0);
    color: rgb(255, 255, 255);
    padding: 16px 52px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 6px;
  }
}
.serviceCardOuterWrapper {
  padding: 15px 36px;
  .serviceCardInnerWrapper {
    height: 400px;
    box-shadow: 0px 0px 100px rgba(0,0,0,0.15);
    border-radius: 6px;
    background-color: white;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .serviceCardIconImg {
      width: 130px;
      height: 130px;
    }
    .cardHeader {
      margin-top: 35px;
      font-size: 1.3rem;
    }
    .smsCardSubHeader {
      text-align: center;
      font-size: 0.9rem;
      color: grey;
    }
    .cardActionBtn {
      margin-top: 35px;
      cursor: pointer;
      padding: 6px 30px;
      font-size: 0.9rem;
      border: 1px solid rgb(0, 128, 255);
      border-radius: 6px;
      box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
    }
    &.smsService {
      .cardActionBtn {
        border: 1px solid #61BCF5;
        &:hover {
          background-color: #61BCF5;
          color: white;
        }
      }
    }
    &.mmsService {
      .cardActionBtn {
        border: 1px solid #420FB7;
        &:hover {
          background-color: #420FB7;
          color: white;
        }
      }
    }
    &.rmService {
      .cardActionBtn {
        border: 1px solid #FF6A4B;
        &:hover {
          background-color: #FF6A4B;
          color: white;
        }
      }
    }
    &.voiceService {
      .cardActionBtn {
        border: 1px solid #FC6DE5;
        &:hover {
          background-color: #FC6DE5;
          color: white;
        }
      }
    }
  }
}
.pricingWrapper {
  // background-color: rgb(233, 178, 0);
  background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-abstract-line-background-design-image_351709.jpg');
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .pricingIconWrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    border-radius: 60px;
    color: rgb(233, 178, 0)!important;
    * {
      color: rgb(233, 178, 0)!important;
    }
    font-size: 2.3rem;
  }
  .pricingHeader {
    font-size: 2rem;
    margin-top: 10px;
    color: white;
  }
  .viewPlansBtn {
    margin-top: 35px;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 0.9rem;
    color: white;
    background-color: rgb(219, 186, 0);
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
  }
}
.contactUsWrapper {
  // padding-top: 8rem;
}
.info-horizontal {
  margin-bottom: -7rem;
}
</style>
